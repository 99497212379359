.modal-img {
  width: 34vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    background-color: rgba(0,0,0,0.6) !important;
}
.modal-modal{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1200px) {
  .modal-img {
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
