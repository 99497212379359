body {
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SFProR";
  src: local("SFProR"),
   url('./Assets/Fonts/SFPro/SFProDisplay-Regular.ttf') format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "SFProB";
  src: local("SFProN"),
   url('./Assets/Fonts/SFPro/SFProDisplay-Bold.ttf') format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "SFProT";
  src: local("SFProT"),
   url('./Assets/Fonts/SFPro/SFProDisplay-Thin.ttf') format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "SFProU";
  src: local("SFProU"),
   url('./Assets/Fonts/SFPro/SFProDisplay-Ultralight.ttf') format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "SFProH";
  src: local("SFProH"),
   url('./Assets/Fonts/SFPro/SFProDisplay-Heavy.ttf') format("truetype");
  font-weight: bold;
  }
  ::selection {
    background: 
    -webkit-linear-gradient(45deg, rgb(255, 144, 144), rgb(228, 169, 255) , rgb(255, 170, 251));
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color:rgb(183, 0, 255);
  }
