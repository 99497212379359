button {
  width: 10rem;
  height: 3rem;
  border-radius: 50px;
  margin-right: 15px;
  border: none;
  font-family: SFProR;
  font-size: 1rem;
  cursor: pointer;
}
.black {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  transition: 0.3s;
}
.black:hover {
  background-color: rgb(0, 0, 0);
  transition: 0.3s;
  color: white;
}
.white {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  transition: 0.3s;
}
.white:hover {
  background-color: rgb(255, 255, 255);
  transition: 0.3s;
  color: black;
}
.welcomeButton {
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  font-size: 18px;
  border-radius: 50px;
  transition: 0.2s;
}
.welcomeButton:hover {
  font-size: 18px;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.2s;
  background-color: black;
}
.preContactButton {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 18px;
  border-radius: 50px;
  transition: 0.2s;
}
.preContactButton:hover {
  font-size: 18px;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  transition: 0.2s;
  background-color: white;
}
@media screen and (max-width: 1200px) {
  button {
    width: 8rem;
    height: 2.5rem;
    border-radius: 50px;
    margin-right: 15px;
    border: none;
    font-family: SFProR;
    font-size: 1rem;
    cursor: pointer;
  }
}
