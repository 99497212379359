.second-page-background {
  background-image: url("../../Assets/Img/06F1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 115vh;
  position: relative;
  bottom: 4rem;
  background-size: cover;
}
.second-page-content {
  flex-direction: column;
  font-size: 80px;
  text-align: left;
  display: grid;
  grid-template-columns: 47rem 90rem;
  grid-template-rows: 12rem 9rem;
  align-items: center;
}
.second-page-content-hide {
  flex-direction: column;
  font-size: 80px;
  text-align: left;
  display: grid;
  grid-template-columns: 47rem 90rem;
  grid-template-rows: 15rem 12rem;
  align-items: center;
  display: none;
}
.aboutForMobile {
  display: none;
}
.second-page-headerMobile {
  display: none;
}
.second-page-header {
  font-size: 60px;
  font-family: SFProT;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  animation: type 1.1s steps(60, end) forwards;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
}
.second-page-text {
  font-size: 43px;
  line-height: 3rem;
  font-family: SFProU;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  width: 1000px;
}
.firstLine {
  animation: type 1.5s steps(60, end) forwards;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  animation-delay: 0.5s;
}
.secondLine {
  animation: type 1.5s steps(60, end) forwards;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  animation-delay: 1.2s;
}
.thirdLine {
  animation: type 1.5s steps(60, end) forwards;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  animation-delay: 1.9s;
}
.forthLine {
  animation: type 1.5s steps(60, end) forwards;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  animation-delay: 2.8s;
}
@keyframes type {
  from {
    opacity: 1;
  }
  to {
    width: 30em;
    opacity: 1;
  }
}
mark.mark {
  background-color: transparent;
  color: black;
  font-family: SFproR;
}
@media screen and (max-width: 1200px) {
  * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
  .second-page-background {
    background-image: url("../../Assets/Img/06F1Mobile.jpg");
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    bottom: 4rem;
    background-size: cover;
    background-position: 19%;
    text-align: left;
    display: grid;
    grid-template-columns: 1rem 21.2rem;
    grid-template-rows: 5rem 4rem;
  }
  .second-page-content {
    display: none;
  }
  .aboutForMobile {
    font-family: SFProT;
    font-size: 22px;
    display: block;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    width: 90vw;
    line-height: 2.5rem;
  }
  .second-page-headerMobile {
    display: block;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    font-family: SFProT;
    font-size: 33px;
  }
  .second-page-content-hide {
    display: none;
  }

  .firstLine {
    display: none;
  }
  .secondLine {
    display: none;
  }
  .thirdLine {
    display: none;
  }
  .forthLine {
    display: none;
  }
}
@media screen and (min-width: 1210px) and (max-width: 1910px) {
  .second-page-header {
    font-size: 44px;
  }
  .second-page-text {
    font-size: 33px;
  }
  .second-page-content {
    grid-template-columns: 37rem 60rem;
    grid-template-rows: 6rem 9rem;
  
  }
}
