.Background {
  background-image: url("../../Assets/Img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  bottom: 4rem;
  background-attachment: fixed;
  background-position: center;
}
.firstpageContent {
  flex-direction: column;
  font-size: 80px;
  text-align: left;
  display: grid;
  grid-template-columns: 6rem 80rem 20rem 20rem;
  grid-template-rows: 29rem 7rem 8rem 6rem;
  align-items: center;
}
.Header {
  font-size: 80px;
  font-family: SFProT;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  animation-name: header;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  position: relative;
  visibility: hidden;
}
@keyframes header {
  from {
    opacity: 0;
    bottom: 20px;
  }
  to {
    opacity: 1;
    bottom: 0px;
    visibility:visible
  }
}
.subHeader {
  font-size: 50px;
  font-family: SFProU;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  animation-name: subheader;
  animation-duration: 1s;
  position: relative;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  visibility: hidden;
}
@keyframes subheader {
  from {
    opacity: 0;
    bottom: 20px;
    
  }
  to {
    opacity: 1;
    bottom: 0px;
    visibility:visible
  }
}
.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  animation-name: buttons;
  animation-duration: 1s;
  position: relative;
  animation-timing-function: ease-in;
}
@keyframes buttons {
  from {
    opacity: 0;
    bottom: 20px;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
}
@media screen and (max-width: 1200px) {
  * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
  .Background {
    background-image: url("../../Assets/Img/background.jpg");
    background-attachment: local;
    background-position: 13%;


  }
  .firstpageContent {
    flex-direction: column;
    text-align: center;
    display: grid;
    grid-template-columns: 6% 1fr 6%;
    grid-template-rows: 20rem 6rem 8rem 6rem;
    align-items: center;
  }
  .Header {
    font-size: 50px;
  }
  .subHeader{
    font-size: 30px;
  }
  .buttons{
    justify-content: center;

  }
}
@media screen and (min-width: 1210px) and (max-width: 1910px) {
  .firstpageContent {
   
    grid-template-columns: 3rem 80rem 20rem 20rem;
    grid-template-rows:  22rem 4rem 7rem 5rem;
  }
  .Header {
    font-size: 60px;

  }
  .subHeader {
    font-size: 40px;
  
  }
}
