.contact-container {
  width: 100%;
  height: 67vh;
  position: relative;
  bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.contact-map-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-map {
  background-image: url("../../Assets/Img/map.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  height: 100%;
}
.contact-text-container {
  width: 100%;
  height: 91%;
  color: white;
  /* background-color: green; */
  text-align: left;
}
.contact-text {
  display: flex;
  font-family: SFProT;
  font-size: 30px;
  margin-left: 5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: blue; */
  width: 80%;
  height: 100%;
}
.contact-header {
  font-size: 40px;
  margin-bottom: 2rem;
}
.contact-about {
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 6rem;
}

.linkedin a {
  color: white;
  text-decoration: none;
  transition: 0.3s;
  position: relative;
  left: 0px;
  color: rgb(255, 188, 111);

} .linkedin{
  margin-bottom: 2rem;

}
.linkedin a:hover {
  color: rgb(165, 87, 255);
  text-decoration: none;
  position: relative;
  left: 10px;
  transition: 0.3s;
}
.contact-name{
  color: rgb(255, 188, 111);
  position: relative;
  left: 0px;
  transition: 0.3s;
  margin-bottom: 2rem;
}
.contact-name:hover{
  color: rgb(165, 87, 255);
  position: relative;
  left: 10px;
  transition: 0.3s;

}
.contact-tel{
  color: rgb(255, 188, 111);
  position: relative;
  left: 0px;
  transition: 0.3s;
}
.contact-tel:hover{
  color: rgb(165, 87, 255);
  position: relative;
  left: 10px;
  transition: 0.3s;
}
.contact-mail{
  margin-top: 2rem;
}
@media screen and (max-width: 1200px) {
  .contact-text {
    display: flex;
    font-family: SFProT;
    font-size: 22px;
    margin-left: 2rem;

  }
  .contact-map-container{
    display: none;
  }
  .contact-map {
   display: none;
  }
  .contact-container {
   
    height: 104vh;
  
  }

}
@media screen and (min-width: 1210px) and (max-width: 1910px) {
  .contact-container {
    width: 100%;
    height: 94vh;
  
  }
}

