.portfolio-background {
  position: relative;
  bottom: 4rem;
  height: 100%;
  width: 100vw;
}
.portfolio-container {
  width: 100%;
  height: 100%;
}

.project1 {
  background-image: url("../../Assets/Img/project1.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 75vh;
  background-position: 50% 49%;
  display: grid;
  grid-template-columns: 63% 33%;
  grid-template-rows: 18% 10% 20% 12%;
  background-attachment: fixed;
}
.project1-header {
  font-family: SFProT;
  font-size: 45px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  text-align: left;
}
.project1-sum {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  text-align: left;
}
.project1-data {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  text-align: left;
}
.project1-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: left;
}
.project2 {
  background-image: url("../../Assets/Img/project2.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 75vh;
  color: white;
  background-position: 50% 100%;
  display: grid;
  grid-template-columns: 3% 34%;
  grid-template-rows: 22% 12% 14% 18%;
}
.project2-header {
  font-family: SFProT;
  font-size: 45px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  text-align: left;
}
.project2-sum {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  text-align: left;
}
.project2-data {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  text-align: left;
}
.project2-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: left;
}
.project2Mobile-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: left;
  display: none;
}
.project3 {
  background-image: url("../../Assets/Img/project3.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: 75vh;
  background-position: 50% -15%;
  display: grid;
  transition: none;
  grid-template-columns: 3% 50%;
  grid-template-rows: 18% 10% 25% 17%;
}
.project3-header {
  font-family: SFProT;
  font-size: 45px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  text-align: left;
}
.project3-sum {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  text-align: left;
}
.project3-data {
  font-family: SFProT;
  font-size: 34px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  text-align: left;
}
.project3-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: left;
}
.project4 {
  background: linear-gradient(
    180deg,
    rgba(207, 202, 198, 1) 0%,
    rgb(255, 227, 204) 100%
  );
}
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1200px) {
  .project1 {
    background-image: url("../../Assets/Img/project1Mobile.jpg");
    background-attachment: local;
    grid-template-columns:8% 1fr 8%;
    grid-template-rows: 7% 8% 13.5% 11% ;
    height: 85vh;
  }
  .project1-header {
    font-family: SFProT;
    font-size: 30px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: left;
    font-family: SFProT;
  }
  .project1-sum {
    font-family: SFProT;
    font-size: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: left;
  }
  .project1-data {
    font-family: SFProT;
    font-size: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: left;
  }
  .project1-buttons {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
    text-align: center;
  }
  .project2 {
    background-image: url("../../Assets/Img/project2Mobile.jpg");
    background-attachment: local;
    grid-template-columns: 8% 1fr 8%;
    grid-template-rows:  7% 8% 8.4% 18% ;
    height: 85vh;
  }
  .project2-header {
    font-size: 30px;
    color: black;
  }
  .project2-sum {
    font-size: 20px;
    color: black;
  }
  .project2-data {
    font-size: 20px;
    color: black;
  }
  .project2-buttons {
    display: none;
  }
  .project2Mobile-buttons {
    font-size: 22px;
    color: black;
    display: block;
    text-align: center;
  }
  .project3 {
    background-image: url("../../Assets/Img/project3Mobile.jpg");
    background-attachment: local;
    background-position: 50% 70%;
    height: 105vh;
    grid-template-columns: 8% 1fr 8%;
    grid-template-rows: 7% 6% 16% 10%;
  }
  .project3-header {
    font-family: SFProT;
    font-size: 30px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: left;
  }
  .project3-sum {
    font-family: SFProT;
    font-size: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: left;
  }
  .project3-data {
    font-family: SFProT;
    font-size: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: left;
  }
  .project3-buttons {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
    text-align: center;
  }
}
@media screen and (min-width: 1210px) and (max-width: 1910px) {
  .project1 {
    background-attachment: fixed;
    grid-template-columns: 63% 33%;
    grid-template-rows: 23% 10% 21% 12%;
    
  }
  .project1-header {
    font-size: 35px;

  }
  .project1-sum {
    font-size: 25px;

  }
  .project1-data {
    font-size: 25px;
  }
  .project2 {
    background-attachment: fixed;
    grid-template-columns: 3% 33%;
    grid-template-rows: 23% 10% 15% 17%;
    
  }
  .project2-header {
    font-size: 35px;

  }
  .project2-sum {
    font-size: 25px;

  }
  .project2-data {
    font-size: 25px;
  }
  .project3 {
    grid-template-columns: 3% 33%;
    grid-template-rows: 21% 10% 26.4% 17%;
    background-attachment: fixed;
    
  }
  .project3-header {
    font-size: 35px;

  }
  .project3-sum {
    font-size: 25px;

  }
  .project3-data {
    font-size: 25px;
  }
}

