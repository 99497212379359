.pre-contact {
  width: 100%;
  height: 95vh;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(21,21,21,1) 100%);
  position: relative;
  bottom: 4rem;
  font-family: SFProU;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 80px;

}
.pre-contact-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pre-contact-buttons {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .pre-contact {

    font-size: 45px;
  
  }
  .pre-contact-text {
    width: 80%;
  }

}
@media screen and (min-width: 1210px) and (max-width: 1910px) {
  .pre-contact {
    height: 115vh;

  }
}

