.css-ki1hdl-MuiAlert-action {
  display: none !important;
}
.contact-email {
  color: rgb(255, 188, 111);
  transition: 1s;
  position: relative;
  left: 0px;
  transition: 0.3s;
}
.contact-email:hover {
  color: rgb(165, 87, 255);
  position: relative;
  cursor: pointer;
  left: 10px;
  transition: 0.3s;
}
.css-1mzcepu{
  display: none !important;

}
