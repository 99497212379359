.Navbar {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  height: 4rem;
  background-color: transparent;
  z-index: 1000;
  animation-name: nonActive;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes nonActive {
  from {
    background-color: black;
  }
  to {
    background-color: transparent;
  }
}
.Navbar.active {
  background-color: yellow(93, 93, 99);
  z-index: 1000;
  animation-name: active;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes active {
  from {
    background-color: transparent;
  }
  to {
    background-color: black;
  }
}

.NavbarContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
}
.NavbarLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.6rem;
  /* border: 1px solid green; */
}
.NavbarLogo {
  background-image: url("../../Assets/Img/logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.65rem;
  height: 1.74rem;
  margin-right: 0.6rem;
}
.NavbarLogoColor {
  background-image: url("../../Assets/Img/logoColor.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.65rem;
  height: 1.74rem;
  margin-right: 0.6rem;
}
.NavbarLogoText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 3px;
  font-family: SFProR;
}
.NavbarLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 30%;
  font-size: 17px;
}
li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  color: white;
  font-family: SFProT;
  letter-spacing: 1px;
  margin: 1rem;
  transition: 0.3s;
}
li:hover {
  cursor: pointer;
  transition: 0.3s;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  padding: 8px 25px 8px 25px;
}

@media screen and (max-width: 1200px) {
  li {
    display: none;
  }
  .NavbarContent{
    padding: 0;
  }
  .NavbarLogoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17.6rem;
    /* border: 1px solid green; */
  }
  .NavbarLogo {
    background-image: url("../../Assets/Img/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.5rem;
    height: 1.6rem;
    margin-right: 0.6rem;
  }
  .NavbarLogoText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: white;
    letter-spacing: 3px;
    font-family: SFProR;
  }
  .NavbarLogoColor {
    background-image: url("../../Assets/Img/logoColor.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.5rem;
    height: 1.6rem;
    margin-right: 0.6rem;
  }
  .Navbar {
    position: static;
    position: -webkit-static;

  }
}
